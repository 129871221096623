<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="UserEditModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="UserEditModal"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="container p-3">
            <div
                class="d-flex col-12 justify-content-between align-items-center"
              >
                <h4 class="modal-heading m-0">{{isEditMode?'Edit':'Add'}} New Webhook</h4>
                <button @click="closeModal" class="btn" style="">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
               <form @submit.prevent="saveChanges()">
              <div class="p-5">
                 <div class="">
                <label for="webhook-events" class=" d-flex w-100  justify-content-between">Events: <span class="text-danger">*{{ error.webhook_id }}</span> </label>
                  <div v-if="isLoadingEdit"
        class="integrationImgae bg-preload"
        style="height: 50px; width: 100%; border-radius: 14px">
        <div class="into-preload"></div>
      </div> 
                <b-form-select v-else v-model="webhook.webhook_id" class="quizell-form-control">
                  <b-form-select-option value="" selected disabled>Select an event</b-form-select-option>
                  <b-form-select-option :value="event.id" v-for="event in events" :key="event.id" >{{event.event_title}}</b-form-select-option>
                 </b-form-select>
               
                 </div>
                <!-- :value="passwordList.current" @input="(e)=>passwordList.current=e" -->
                
                 <div class="mt-3">
                 <label for="webhook-events" class=" d-flex w-100  justify-content-between">Webhook URL: <span class="text-danger">*{{ error.webhook_url }}</span> </label>
                <div class="">
                    <div v-if="isLoadingEdit"
        class="integrationImgae bg-preload"
        style="height: 50px; width: 100%; border-radius: 14px">
        <div class="into-preload"></div>
                </div>
                  <input type="url" v-else autocomplete="none" v-model="webhook.webhook_url" id="webhook-name" class="px-2 quizell-form-control w-100" placeholder="Webhook URL">
                </div>
                 </div>
                  <div class="mt-3" v-if="isLoadingEdit">
                  <label for="webhook-events" class=" d-flex w-100  justify-content-between">Quiz: <span class="text-danger">*{{ error.quiz_id }}</span> </label>
                   <div 
        class="integrationImgae bg-preload"
        style="height: 50px; width: 100%; border-radius: 14px">
        <div class="into-preload"></div>
                 </div>
                 </div>
                 <div class="mt-3" v-else-if="isShowQuizzes">
                  <label for="webhook-events" class=" d-flex w-100  justify-content-between">Quiz: <span class="text-danger">*{{ error.quiz_id }}</span> </label>
                  <b-form-select  class="quizell-form-control"  v-model="webhook.quiz_id">
                   <b-form-select-option value="" selected disabled>Select an event</b-form-select-option>
                  <b-form-select-option :value="quiz.id" v-for="quiz in quizzes" :key="quiz.id" >{{quiz.title}}</b-form-select-option>
                 </b-form-select>
                 </div>
                
              </div>
               <div class="d-flex justify-content-center">
               
                  <Button type="submit" :is-loading="isLoadingSaveChanges||isLoadingEdit">Save Changes</Button>
               
                </div>
                </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


// import axios from 'axios'
import $ from "jquery";
import axios from 'axios'
import Button from "../Layout/Button.vue";

export default {
  data() {
    return {
    events:[],
    updateId:'',
    quizzes:[],
    webhook:{
    "webhook_id" : '',
    "quiz_id" : '',
    "webhook_url" : ''},
    requiredFields:['webhook_id','quiz_id','webhook_url'],
    error:{},
    isEditMode:false,
    isLoadingSaveChanges:false,
    isLoadingEdit:false
    }
  },
components:{

    Button
},
 computed:{
  isShowQuizzes(){
    const index = this.events.findIndex(event=>event.id==this.webhook.webhook_id&&event.event_apply=='quiz')
    if(index>-1)
    return true;
  return false
  },
  axiosAPICheck(){
    return {
      axiosMethod:'post',
      resource:'webhooks',
      method: this.isEditMode?'update':'save',
      params:this.webhook,
      id:this.updateId
    }
  },
 },
  methods: {
    validationCheck(){
       this.error={}
     
      for(const fieldName of this.requiredFields){
        
        if((!this.webhook[fieldName]))
        this.error[fieldName]='required'
      }
        const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
         if (this.webhook.webhook_url!=''&&!urlRegex.test(this.webhook.webhook_url)) {
          this.error['webhook_url'] = 'Invalid URL format.';
        }
        
        if(this.error.quiz_id&&(!this.isShowQuizzes))
        delete this.error.quiz_id
        return Object.keys(this.error).length === 0;
    },
   async saveChanges(){

    try {
      this.isLoadingSaveChanges=true;

      if(this.validationCheck()){
        this.isLoadingSaveChanges=true;
        let response
        

        if(this.isEditMode){
          response = await axios.post(`/user/webhooks/update/${this.updateId}`,this.webhook)
        } 
        else{
          response = await axios.post(`/user/webhooks/store`,this.webhook)
        }
       if(response&&response.data.status=='success')
       { 
        const alert =+this.isEditMode?' Updated ':' Created '
      
        this.toast('Webhook' + alert + 'Success',this.isEditMode);
        this.closeModal();
        this.$emit('reload')
       }
       else
        this.toast('Something went Wrong.');
      }
      
    } catch (error) {
      if(error && error.response){
        console.log(error.response)
        let errorText = error.response.data.message
        this.toast(errorText);
      }
      
    } finally{
      this.isLoadingSaveChanges=false;
    }

      
       
    },
     toast(text){
       this.$toasted.show(text, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
    },
   async getEditDetail(){
    this.isLoadingEdit=true;
      const response = await this.$store.dispatch('fetchData',{
        axiosMethod:'get',
        resource:'webhooks',
        method:'edit',
        id:this.updateId

      });
      if(response&&response.status=='success'){
        this.webhook=response.data
      }
      else this.getEditDetail
       this.isLoadingEdit=false;
    },
    OpenModal(mode=false,id='') {
    this.webhook={
    "webhook_id" : '',
    "quiz_id" : '',
    "webhook_url" : ''};
      this.updateId=id;
      this.isEditMode = mode;
      // if(mode=='edit')
      $("#UserEditModal").modal("show");
      if(this.isEditMode)
      this.getEditDetail()
    },
    closeModal() {
      $("#UserEditModal").modal("hide");
    },
    
    async getQuizzes(){
      const response = await this.$store.dispatch('fetchData',{
        axiosMethod:'get',
        resource:'webhooks',
        method:'quizList'
      })
      if(response&&response.status=='success')
      {
        this.events=response.data.webhooks;
        this.quizzes=response.data.quizzes;
      }
    }
},
mounted(){
  this.getQuizzes()
}
};
</script>
<style>
.pointer-event-none{
pointer-events: none;
}
.btn-submit {
  color: #ffffff;
  font-size: 14px;

  align-items: center;
  padding: 10px 16px;
  gap: 10px;

  width: 139px;
  height: 41px;

  background: #ffa201;
  border-radius: 8px;
}

.vue-tel-input:focus-within {
  color: #495057;
  background-color: #fff;
  border-color: #f7f6f9;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
#user-detail__BV_label_ {
  /* margin:0px; */
  font-size: 14px;
  color: #73738d;
}
</style>
<style scoped>
.btn-submit:hover {
  color: #ffffff;
}
.btn-submit:active {
  background: #dd9315;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #f7f6f9;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
.upload-image {
  text-decoration-line: underline;
  color: #ffa201;
  font-size: 14px;
  cursor: pointer;
}
.UserEditModalStyle {
  max-height: 80vh;
  overflow: hidden scroll;
  scroll-behavior: smooth;
}
.modal-heading {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
.modal-content {
  border-radius: 20px;
  overflow: hidden;
}
.modal-btn {
  background: #ffa201;
  color: #ffffff;
  border: none;
}
.modal-btn:hover {
  color: #ffffff;
}

.btn-profile-seeting {
  border: 1px solid #ffa201;
  color: #ffa201;
}
.btn-profile-seeting:hover {
  border: 1px solid #ffa201;
  color: #ffffff;
  background: #ffa201;
}

.TitleHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;

  color: #18191c;
}
.quizell-form-control{
border: none;
height:48px;
border-radius: 10px;
border: 1px solid #D2D8E0;
border-radius: 8px;
}
.quizell-form-control:focus-visible{
    border: none!important;;
    outline: none;
     box-shadow: 0 0 0 0.1rem #4d1b7e;
}

</style>
